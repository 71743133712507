import React from 'react'

import { Document, Page, pdfjs } from 'react-pdf/dist/entry.webpack'
import {Link} from 'gatsby'

import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {PhotoSwipe} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import Footer from './footer'
import pdf from '../../static/pdf.pdf';
import Modal from 'react-modal';
import Masonry from 'react-masonry-css';
import bg6 from '../../static/bg-6.png';
import bg1 from '../../static/bg-1.jpg';

import bg5 from '../../static/bg-5.png';
import bg10 from '../../static/bg-10.jpg';
import {CircleSpinner} from "react-spinners-kit"





const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    height: '80vh'


  },
  overlay: {zIndex: 1000}
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
Modal.setAppElement('#___gatsby')
class Mainpage extends React.Component {
  constructor(props) {
    super(props)

    this.iconlist = this.props.data.workservices.entities.map((item)=> {
      return (
        {
          src: item.fieldWorkimage.entity.thumbnail.imageFile.childImageSharp.resize.src,
          w: 1200,
          h: 900,
          title: item.fieldCategory.entity.name,
          id: item.fieldWorkimage.entity.thumbnail.imageFile.id
        }
      )
    })

    this.state= {
      photoitems:null,

      options:null,
      isOpen: false,
      name: '',
      nameerror:'',
      email: '',
      emailerror:'',
      message: '',
      messageerror:'',
      loading:false,
      netresponse: '',
      numPages: null,
      pageNumber: 1,
      modalIsOpen: false,
      pdfFile:'',
      scale:1,
      showListItemPdf: false,

    }
    this.openModal = this.openModal.bind(this);

  this.closeModal = this.closeModal.bind(this);
  }

  handleSliderChange(e) {

    var $ = window.$

    $('.onslider').removeClass('animated fadeInDown').addClass('animated fadeOutUp')
    setTimeout(()=> {
      $('.onslider').removeClass('fadeOutUp').addClass('fadeInDown')
    }, 1500)

  }
  openModal() {
  this.setState({modalIsOpen: true});
}


closeModal() {
  this.setState({modalIsOpen: false, scale:1});
}

handleImageViewer = (id, category) => {
  let index = 0;

  let list = this.iconlist.filter((item) => item.title === category)
  list.forEach((item,i) => {
    if(item.id === id) {
      index = i
    }
  })
  this.setState({
    photoitems:list,
    options:{index:index, history:false},
    isOpen:true
  })

}


handleClose = () => {
 this.setState({isOpen:false})
}

onDocumentLoadSuccess = (document) => {
  const { numPages } = document;
  this.setState({
    numPages,
    pageNumber: 1,
  });
};

changePage = offset => this.setState(prevState => ({
  pageNumber: prevState.pageNumber + offset,
}));

previousPage = () => this.changePage(-1);

nextPage = () => this.changePage(1);

  handleChange = (e) => {
 
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handlePdf = (e) => {
    e.preventDefault()
 
  }

  handlescale = (status) => {
   
    if(status === 'plus') {
      this.setState({
        scale: this.state.scale + this.state.scale*0.5
      })
    }
    else {
      this.setState({
        scale: this.state.scale - this.state.scale*0.5
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    var {name,email,message} = this.state
    
    if(!name) {
      this.setState({
        nameerror: this.props.language === 'EL'? 'KENO ONOMA' : 'NOT NULL'
      })
    }
    else {
      this.setState({
        nameerror: ''
      })
    }

    if(!email.match(pattern)) {
      this.setState({
        emailerror: this.props.language === 'EL'? 'ΛΑΘΟΣ EMAIL' : 'INVALID EMAIL'
      })
    }
    else {
      this.setState({emailerror:''})
    }
    if(!message) {
      this.setState({
        messageerror: this.props.language === 'EL'? 'KENO ΜΗΝΥΜΑ' : 'NOT NULL'
      })
    }
    else {
      this.setState({messageerror:''})
    }

    if(name && message && email.match(pattern) ) {
     
      this.setState({loading:true, name:'', message:'', email:''})
      var data = {

                "data" : {



                                "data" : {

                                                "name" : this.state.name,

                                                "email" : this.state.email,



                                                "message" : this.state.message

                                }

                }

  }
  var url = `${process.env.BASE_URL}/wd_webform/contact/submit`
  fetch(url, {
   method:'post',
   mode: 'cors',
   // headers: {
   //      'Content-Type': 'application/json',
   //      // 'Content-Type': 'application/x-www-form-urlencoded',
   //  },
   body:JSON.stringify(data)
  })
  .then(function(response) {


  return response.json()


  })
  .then(res => {
   
   if(res.data.submission_id) {
     this.setState({loading:false, netresponse:'Message sent'})
   }
   else {
      this.setState({loading:false, netresponse:'Network Error'})
   }


  })
  .catch((error) => {
      this.setState({loading:false, netresponse:'Network Error'})
     console.log("in error", error)

  })
    }



  }

  componentDidMount() {
    var count =1;
    var $ = window.$
    setTimeout(() => {
      $('.hide').removeClass('hide')
      $('.onslider').addClass('animated fadeInDown')
       // $('.selected').find('h1.hide').removeClass('hide').addClass('animated slideInLeft')




    }, 1500)

   this.interval1 = setInterval(()=> {
      $('.bg-moving').css('background-position', `-${count}px 0`);
      count = count + 2;
    }, 50)

    setTimeout(()=> {
      var $container = $('.portfolio-wrap');
          $container.isotope({
              itemSelector: '.masonry-item',
              transitionDuration: '1s',
    originLeft: true,
    stamp: '.stamp',
          });

          $container.isotope({ filter: '*' });

      $('.masonry-filter li').on('click',function() {
          var selector = $(this).find("a").attr('data-filter');
          $('.masonry-filter li').removeClass('active');
          $(this).addClass('active');
          $container.find(".hidden").removeClass("hidden");
          $container.isotope({ filter: selector });
          counter = initShow;
          loadMore(initShow);
          return false;
      });


      //****************************
    // Isotope Load more button
    //****************************
    var initShow = 6; //number of images loaded on init & onclick load more button
    var counter = initShow; //counter for load more button
    var iso = $container.data('isotope'); // get Isotope instance

    loadMore(initShow); //execute function onload

    function loadMore(toShow) {

       $container.find(".hidden").removeClass("hidden");



      var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
        return item.element;
      });

      $(hiddenElems).addClass('hidden');
      $container.isotope('layout');

      //when no more to load, hide show more button


    }

    //append load more button


    //when load more button clicked




  }, 300)

    //HACK REMOVE ITEMS FROM ALL
    // this.interval2 = setInterval(()=> {
    //    var $ = window.$;
    //    var parent = $('[data-hover="All"]').parent()
    //    if($(parent).hasClass('active')) {
    //      console.log('is active')
    //      var cs =  $('ul.filter-navigation').children().children()
    //      $(cs).each(() => {
    //        console.log($(this))
    //      })
    //    }
    //
    //  }, 500)

    $('.owl-carousel').each(function() {
  var $carousel = $(this);
  $carousel.find('> *').each(function (i) {
    $(this).attr('data-index', i);
  });
  var data = $carousel.data();

  var loop =  true,
    margin = (data.margin || data.margin === 0) ? data.margin : 30,
    nav = data.nav ? data.nav : false,
    navPrev = data.navPrev ? data.navPrev : '<i class="fa fa-angle-left">',
    navNext = data.navNext ? data.navNext : '<i class="fa fa-angle-right">',
    dots = data.dots ? data.dots : false,
    themeClass = data.themeclass ? data.themeclass : 'owl-theme',
    center = data.center ? data.center : false,
    items = data.items ? data.items : 4,
    autoplay = data.autoplay ? data.autoplay : false,
    responsiveXs = data.responsiveXs ? data.responsiveXs : 1,
    responsiveSm = data.responsiveSm ? data.responsiveSm : 2,
    responsiveMd = data.responsiveMd ? data.responsiveMd : 3,
    responsiveLg = data.responsiveLg ? data.responsiveLg : 4,
    draggable = (data.draggable === false) ? data.draggable : true,
    syncedClass = (data.syncedClass) ? data.syncedClass : false,
    filters = data.filters ? data.filters : false;

  if (filters) {
    $carousel.after($carousel.clone().addClass('owl-carousel-filter-cloned'));
    $(filters).on('click', 'a', function( e ) {
      //processing filter link
      e.preventDefault();
      if ($(this).hasClass('selected')) {
        return;
      }
      var filterValue = $( this ).attr('data-filter');
      $(this).siblings().removeClass('selected active');
      $(this).addClass('selected active');

      //removing old items
      for (var i = $carousel.find('.owl-item').length - 1; i >= 0; i--) {
        $carousel.trigger('remove.owl.carousel', [1]);
      };

      //adding new items
      var $filteredItems = $($carousel.next().find(' > ' +filterValue).clone());
      $filteredItems.each(function() {
        $carousel.trigger('add.owl.carousel', $(this));
        $(this).addClass('scaleAppear');
      });

      $carousel.trigger('refresh.owl.carousel');

      //reinit prettyPhoto in filtered OWL carousel
      if ($().prettyPhoto) {
        $carousel.find("a[data-gal^='prettyPhoto']").prettyPhoto({
          hook: 'data-gal',
          theme: 'facebook' /* light_rounded / dark_rounded / light_square / dark_square / facebook / pp_default*/
        });
      }
    });

  } //filters

  $carousel.owlCarousel({
    loop: loop,
    margin: margin,
    nav: nav,
    autoplay: autoplay,
    dots: dots,
    themeClass: themeClass,
    center: center,
    navText: [navPrev,navNext],
    mouseDrag: draggable,
    touchDrag: draggable,
    items: items,
    responsive: {
      0:{
        items: responsiveXs
      },
      767:{
        items: responsiveSm
      },
      992:{
        items: responsiveMd
      },
      1200:{
        items: responsiveLg
      }
    },
  })
  .addClass(themeClass);
  if(center) {
    $carousel.addClass('owl-center');
  }



  //topline two synced carousels
  if($carousel.hasClass('owl-news-slider-items') && syncedClass) {
    $carousel.on('changed.owl.carousel', function(e) {
      var indexTo = loop ? e.item.index+1 : e.item.index;
      $(syncedClass).trigger('to.owl.carousel', [indexTo]);
    })
  }


});


  }

  componentWillUnmount(){
    clearInterval(this.interval1);

  }
  render() {


  
    
   const { pageNumber, numPages } = this.state;
    var newarr={}
    return (

      

      <div>
        
        <PhotoSwipe isOpen={this.state.isOpen} items={this.state.photoitems} options={this.state.options} onClose={this.handleClose}/>
      <div className="mainpagecontainer">
        {/* Section FirstSlider */}
        <Modal
  isOpen={this.state.modalIsOpen}

  onRequestClose={this.closeModal}
  style={customStyles}
  contentLabel="Example Modal"
>

   <div className="row bg-light">
     <div className="col-sm">
  <button onClick={this.closeModal}>close</button>
  </div>
    <div className="col-sm">



    <div>

      <button
        type="button"
        disabled={pageNumber <= 1}
        onClick={this.previousPage}
      >
        Previous
      </button>
      <button
        type="button"
        disabled={pageNumber >= numPages}
        onClick={this.nextPage}
      >
        Next
      </button>
    </div>
    </div>
    <div className="col-sm">
      <span style={{fontSize:'20px'}} onClick={() => this.handlescale('minus')} className="fas fa-search-minus m-3 hoverable"></span>
        <span style={{fontSize:'20px'}} onClick={()=> this.handlescale('plus')} className="fas fa-search-plus m-3 hoverable"></span>
    </div>
    </div>
    <div>
      <Document
        file={this.state.pdfFile}
        onLoadSuccess={this.onDocumentLoadSuccess}

      >
        <Page scale={this.state.scale} width={700} pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={this.previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={this.nextPage}
        >
          Next
        </button>
      </div>
    </div>
</Modal>

        <div className="carouselcontainer1">
      <Carousel dynamicHeight={true} transitionTime={1100} showStatus={false} showIndicators={false} onChange={this.handleSliderChange} autoPlay={true} infiniteLoop={true} interval={5000}>
           {this.props.data.slidequery.entities.map((item,i) => {
             return (
               <div key={i}>

                   <img className="croped2 contrast" style={{maxHeight: '700px'}}  src={item.fieldSlideimage.entity.thumbnail.imageFile.childImageSharp.resize.src} alt={item.fieldSlideimage.entity.thumbnail.alt} />

                   <div className="onslider hide text-white">
                     <div className="movingbox hide animated infinite pulse">
                     </div>

                     <div className="slidercontent">
                       <div style={{position:'relative'}}  className="animation-block1 bounce-1"></div>
                         <div style={{position:'relative', left:'40%'}}  className="animation-block2 bounce-2"></div>
                           <div style={{position:'relative', left:'70%'}}  className="animation-block1 bounce-1"></div>



                       <div dangerouslySetInnerHTML={{ __html: item.body.processed }}>

                       </div>

                       <div className="slidebutton hide">
                         <Link className="site-button-secondry btn-effect" to={item.fieldButton.url.path}>{item.fieldButton.title}</Link>

                       </div>

                   </div>
                   </div>
               </div>
             )
           })}





 </Carousel>
 </div>



 {/**** Section Certification *****/}



          <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
          
            <div className="container">

              <div className="section-content">

                <div className="row">
                  <div className="col-md-7  d-md-block d-none">
                    <div className="video-section-full-v2">
                      <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: `url(${this.props.data.about.entities[0].fieldImageForRegionCertifica.entity.thumbnail.imageFile.childImageSharp.resize.src})` }}>

                        <div className="video-section-inner">
                          <div className="video-section-content">
                            <div className="video-section-left">

                              <span className="ripple"></span>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Desktop Certification */}
                  <div className="col-md-5 col-sm-6 d-none d-md-block">
                    <div className="video-part-2">

                      <h3>{this.props.language === 'EN' ? 'Recent Certifications' : 'Πρόσφατες Πιστοποιήσεις'}</h3>

                      <div className="certcontainer row no-gutters">
                      
                        <ul className="list-angle-right anchor-line">

                          {this.props.data.certs.entities.filter((item) => {
                            
                            // if not find fieldImage return
                            return !item.fieldImage

                          }).map((item, i) => {

                            // if have one pdf return
                            if(item.fieldPdf.length === 1){
                              return (
                                <li key={i}><a href="#" onClick={(e) => {
                                  e.preventDefault()
                                  this.setState({
                                    modalIsOpen: true,
                                    pdfFile: item.fieldPdf[0].pdfFile.publicURL
                                  })
                                }}>{item.entityLabel}</a></li>
                              )
                            }

                            // else if have more than one
                            else if(item.fieldPdf.length > 1){
                              
                              return (
                               <li  key={i} onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                  showListItemPdf: !this.state.showListItemPdf,
                                  // modalIsOpen: true,
                                })
                              }}>
                                <a  href="#" >
                                  {item.entityLabel}
                                </a>
                             

                               {this.state.showListItemPdf === true && (

                                <ul className="list-angle-right anchor-line mt-3 mb-2">
                                  {item.fieldPdf.map((pdf,index) => {

                                    const filename = pdf.entity.url.split('/').slice(-1)[0]

                                    return (
                                      <li key ={index}>
                                        <a href="#" onClick={(e) => {
                                          e.preventDefault()
                                          this.setState({
                                            modalIsOpen: true,
                                            pdfFile: pdf.pdfFile.publicURL
                                          })
                                        }} >{filename}</a>
                                      </li>
                                    )
                                  })}
                                 
                                </ul>

                                 )} 
                                
                               </li>
                              )
                            }
                          })}
                        </ul>

                        <Masonry
                          breakpointCols={2}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column">
                          {this.props.data.certs.entities.filter((item) => {
                            if (item.fieldImage) {
                              return true
                            }
                            else {
                              return false
                            }
                          }).map((item, i) => {

                         


                            // if pdf have a image show the image
                            if (item.fieldPdf.length > 0) {

                              return (
                                <div key={i}>
                                  <img className="hoverable " onClick={() => {
                                    this.setState({
                                      modalIsOpen: true,
                                      pdfFile: item.fieldPdf[0].pdfFile.publicURL
                                    })
                                  }} src={item.fieldImage.imageFile.publicURL} alt={item.fieldImage.alt} />
                                </div>
                              )

                            }
                            else {

                              return (
                                <div key={i}>
                                  <img key={i} src={item.fieldImage.imageFile.publicURL} alt={item.fieldImage.alt} />
                                </div>
                              )
                            }

                          })}
                        </Masonry>


                      </div>

                    </div>
                  </div>
                   {/* end of Desktop Certification */}

                  
                  {/* Mobile Certification */}
                  <div className="col-12 d-block d-md-none">

                    <div className="video-part-2">
                      <h3>{this.props.language === 'EN' ? 'Recent Certifications' : 'Πρόσφατες Πιστοποιήσεις'}</h3>

                      <ul className="list-angle-right anchor-line">
                        {this.props.data.certs.entities.filter((item) => {
                          return !item.fieldImage
                        }).map((item, i) => {
                          if(item.fieldPdf.length === 1){
                            return (
                              <li key={i}><a href={item.fieldPdf[0].pdfFile.publicURL} target="_blank" >{item.entityLabel}</a></li>
                            )
                          }

                          else if (item.fieldPdf.length > 1) {

                            return (
                              <li  key={i} 
                            //   onClick={(e) => {
                            //    e.preventDefault()
                            //    this.setState({
                            //      showListItemPdf: !this.state.showListItemPdf,
                            //    })
                            //  }}
                             >
                               <a>
                                 {item.entityLabel}
                               </a>
                            

                              {/* {this.state.showListItemPdf === true && ( */}

                               <ul className="list-angle-right anchor-line mt-3 mb-2">
                                 {item.fieldPdf.map((pdf,index) => {

                                   const filename = pdf.entity.url.split('/').slice(-1)[0]

                                   return (
                                     <li key ={index}>
                                       <a href={pdf.pdfFile.publicURL} target="_blank" 
                                      //  onClick={(e) => {
                                         
                                      //    this.setState({
                                      //      modalIsOpen: true,
                                      //      pdfFile: pdf.pdfFile.publicURL
                                      //    })
                                      //  }} 
                                       >{filename}</a>
                                     </li>
                                   )
                                 })}
                                
                               </ul>

                                {/* )}  */}
                               
                              </li>
                             )

                          }
                        })}
                      </ul>

                      <div className="certcontainer">
                        <div className="owl-carousel gallery-owl-nav" data-autoplay="true" data-responsive-lg="3" data-responsive-md="2" data-responsive-sm="2" data-responsive-xs="2" data-nav="false" data-dots="true" data-filters=".gallery-filters" data-margin="0" data-loop="true">

                          {this.props.data.certs.entities.filter((item) => {
                            if (item.fieldImage) {
                              return true
                            }
                            else {
                              return false
                            }
                          }).map((item, i) => {

                            
                            // if pdf have a image show the image
                            if (item.fieldPdf.length === 1) {

                              return (
                                <div key={i} className="hoverable vertical-item item-gallery mr-2 content-absolute text-center ds ">
                                  <a className="d-block" target="_blank" href={item.fieldPdf[0].pdfFile.publicURL}>
                                    <img className="contained" style={{ maxWidth: '100%', maxHeight: '200px' }} src={item.fieldImage.imageFile.publicURL} alt={item.fieldImage.alt} />
                                  </a>
                                </div>

                              )

                            }
                            else {

                              return (
                                <div key={i} className="contained vertical-item item-gallery mr-2 content-absolute text-center ds ">
                                  <img style={{ maxWidth: '100%', maxHeight: '200px' }} src={item.fieldImage.imageFile.publicURL} alt={item.fieldImage.alt} />
                                </div>
                              )
                            }




                          })}




















                        </div>



                      </div>
                    </div>




                  </div>
                   {/* Mobile Certification */}

                </div>

              </div>

            </div>

          </div>



 {/**** End of Section Certification *****/}






            {/* Section About Η ΕΤΑΙΡΕΙΑ ΜΑΣ*/}

            <div className="section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat bg-moving" style={{backgroundImage: `url(${bg6})`}}>
                <div className="container">

					<div className="section-head">
						<div className="mt-separator-outer separator-center">
							<div className="mt-separator">
                {this.props.language === 'EN'? 	<h2 className="text-white text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Company</h2>
                :	<h2 className="text-white text-uppercase sep-line-one ">Η <span className="font-weight-300 text-primary">εταιρεια</span> μας</h2> }

							</div>
						</div>
					</div>


						<div className="section-content">
                        	<div className="row">
                            	<div className="col-md-7 col-sm-12 carouselcontainer2">
                                <Carousel showStatus={false} dynamicHeight={true} showArrows={false} showThumbs={false}>
                         {this.props.data.about.entities[0].fieldAbouticon.map((item,i)=> {
                           return (
                             <div key={i}>
                              <img className="croped2" style={{maxHeight:'450px'}} src={item.entity.thumbnail.imageFile.childImageSharp.resize.src} alt={item.entity.thumbnail.alt}/>
                             </div>
                           )
                         })}
            </Carousel>
                           		</div>

                                <div className="col-md-5 col-sm-12">
                                	<div className="about-home-right bg-white p-a30">

                                          <div dangerouslySetInnerHTML={{__html:this.props.data.about.entities[0].body.processed }}></div>

                                    </div>
                                </div>
                    		</div>
                       </div>
                 </div>

            </div>

            {/* end of Section About Η ΕΤΑΙΡΕΙΑ ΜΑΣ*/}



            {/* Section Services ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ*/}

            <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{backgroundImage: `url(${bg5})`}}>
				<div className="section-content">

                        <div className="container">

                            <div className="section-head m-b50">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                      {this.props.language === 'EN'? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Services</h2>
                                    : <h2 className="text-uppercase sep-line-one ">Οι  <span className="font-weight-300 text-primary">Υπηρεσιες</span> μας</h2> }

                                    </div>
                                </div>

                            </div>


                            <div className="row services-v2 no-col-gap">
                              {this.props.data.services.entities.map((item,i) => {
                                return (
                                  <div key={i} className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc">

                                      <div className="mt-icon-box-wraper bdr-l-1 bdr-solid bdr-gray">
                                          <div className="relative  p-a30">

                                              <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                  <span className="icon-cell"><img src={item.fieldServiceicon[0].entity.thumbnail.imageFile.publicURL} alt={item.fieldServiceicon[0].entity.thumbnail.alt}/></span>
                                              </div>
                                              <div className="icon-content">
                                                  <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                  <div dangerouslySetInnerHTML={{__html: item.body.processed}}></div>

                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                )
                              })}


                            </div>

                        </div>
                </div>

            </div>

            {/* End of Section Services ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ*/}



             {/* Section Contact Επικοινωνια  */}
            <div className="section-full p-tb80 overlay-wraper bg-center bg-parallax bg-cover" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${bg1})`, backgroundPosition: '50% -12.5834px'}}>
            	<div className="overlay-main bg-primary opacity-07"></div>
                <div className="container">

						<div className="section-content">
							<div className="call-us-section text-center">
                                <h4 className="m-b15">{this.props.language === 'EN'? 'For more informations' : 'Για περισσότερες πληροφορίες'}</h4>
                                <h2 className="call-us-number m-b15 m-b0">{this.props.data.contactInfo.fieldPhone}</h2>
                                <h4 className="call-us-address m-t0 m-b20">{this.props.data.contactInfo.fieldAddress}</h4>
                                <Link className="site-button-secondry btn-effect bg-dark" to={this.props.language === 'EN'? '/en/contact': '/el/contact'}>
                                 {this.props.language === 'EN'? 'Contact us': 'Επικοινωνια'}
                                </Link>

							</div>
						</div>

                </div>
            </div>
            {/* End of Section Contact  Επικοινωνια*/}



              {/* section ΤΑ ΕΡΓΑ ΜΑΣ */}
            <div className="section-full p-tb80 inner-page-padding">
               <div className="container">
               
                {/* title  ΤΑ ΕΡΓΑ ΜΑΣ*/}
                <div className="section-head">
                  <div className="mt-separator-outer separator-center">
                    <div className="mt-separator">
                      {
                      this.props.language === 'EN'
                        ? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Project</h2>
                        : <h2 className="text-uppercase sep-line-one ">Τα <span className="font-weight-300 text-primary">Εργα</span> μας</h2> 
                      }
                    </div>
                    </div>
                </div>

                {/* navigation ΤΑ ΕΡΓΑ ΜΑΣ */}
                <div className="filter-wrap p-b30 text-center">
                    <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">

                        <li className="active"><a data-filter="*" href="#" data-hover="All">All</a></li>
                          {this.props.data.workservices.entities.filter((item)=> {
                            if(!item.fieldCategory.entity || !item.fieldWorkimage.entity) {
                              return false;
                            }
                            else {
                              var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis
                              if(newarr[nameclass]) {
                                return false;

                              }
                              else {
                                newarr[nameclass]=nameclass
                                return true;
                              }
                            }
                          }).map((item,i) => {





                            var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis

                              return (
                                <li key={i} className="">
                    <a href="#" data-filter={`.${nameclass}`} data-hover={item.fieldCategory.entity.name}>{item.fieldCategory.entity.name}</a>
                                </li>

                              )


                        })}

                    </ul>
                </div>
                {/* end of navigation ΤΑ ΕΡΓΑ ΜΑΣ */}

                 

                {/* Imgage Galery ΤΑ ΕΡΓΑ ΜΑΣ */}
                <div className="portfolio-wrap mfp-gallery work-grid row clearfix" >
                    {/*<!-- COLUMNS 1 --> */}

                    {this.props.data.workservices.entities.filter((item)=> {
                      if(!item.fieldCategory.entity || !item.fieldWorkimage.entity) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }).map((item,i) => {

                        var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis
                              return (
                                <div key={i} className={`masonry-item ${nameclass}  col-md-4 col-sm-6 m-b30`} >
                                    <div className="mt-box image-hover-block hoverable">
                                      <div className="mt-thum-bx">
                                          <img className="croped" src={item.fieldWorkimage.entity.thumbnail.imageFile.childImageSharp.fixed.src} alt={item.fieldWorkimage.entity.thumbnail.alt}/>
                                      </div>
                                      <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">{item.fieldCategory.entity.name.toUpperCase()}</h4>
                                            <p className="m-b0">{item.body? item.body.summary: null}</p>
                                      </div>





                                      <a onClick={() => this.handleImageViewer(item.fieldWorkimage.entity.thumbnail.imageFile.id, item.fieldCategory.entity.name) }>

                                      </a>

                                    </div>
                                </div>
                              )


                    })}



                </div>
                {/*  end of Imgage Galery ΤΑ ΕΡΓΑ ΜΑΣ */}

                     <Link className="site-button btn-effect" to={`/${this.props.language.toLowerCase()}/projects`}>
                       {this.props.language === 'EN'? 'See all our Projects': 'Δειτε ολα τα εργα μας'}
                     </Link>
               </div>


             </div>
              {/* end of section ΤΑ ΕΡΓΑ ΜΑΣ */}







    {/* section get in touch  */}
    <div className="section-full bg-dark ">
       <div className="section-content">
                   <div className="container get-in-touch-form">

                       <div className="row">



                           <div className="col-md-6 col-sm-6">
                               <div className="contact-home1-left contact-home1-left-v2 bg-dark p-a30 m-b30 p-t50">
                                   <h3 className="text-white m-t0">
                                     {this.props.language === 'EN'? <span className="font-weight-100">Get In Touch</span>  : <span className="font-weight-100">Επικοινωνια</span> }

                                     </h3>
                                   <form className="cons-contact-form2 form-transparent" method="post" action="form-handler2.php">

                                       <div className="input input-animate">

                                           <input onChange={this.handleChange} value={this.state.name} type="text" name="name" placeholder={this.props.language === 'EL'? 'ONOMA' : 'NAME'} />
                                           <div style={{color:'red'}}>{this.state.nameerror}</div>
                                       </div>

                                       <div className="input input-animate">

                                           <input onChange={this.handleChange} value={this.state.email} type="email" name="email" placeholder={this.props.language === 'EL'? 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ' : 'EMAIL'} />
                                            <div style={{color:'red'}}>{this.state.emailerror}</div>
                                       </div>



                                       <div className="input input-animate">

                                           <textarea onChange={this.handleChange} value={this.state.message} name="message" placeholder={this.props.language === 'EL'? 'ΜΗΝΥΜΑ' : 'MESSAGE'} ></textarea>
                                          <div style={{color:'red'}}>{this.state.messageerror}</div>
                                            <CircleSpinner
size={30}
color="#686769"
loading={this.state.loading}
/>
                            <h3 style={{color:'grey'}}>{this.state.netresponse}</h3>
                                       </div>

                                       <div className="text-left p-t10">
                                           <button onClick={this.handleSubmit} type="submit" className="site-button btn-effect ">
                                             {this.props.language === 'EN'? 'Submit Now' : 'Αποστολη'}

                                           </button>
                                       </div>
                                   </form>
                               </div>
                           </div>


                           <div className="get-in-touch-form-section">
                               <div className="get-in-touch-form-section-img"><img src="/skalwsies.png" alt="skalwsies"/></div>
                               <div className="get-in-touch-info arrow-animation">
                                   <div className="mt-icon-box-wraper  p-a20 left bg-white hover-shadow">
                                       <div className="icon-md m-b20 scale-in-center">
                                           <span className="icon-cell text-secondry"><img src="/icon-img-01.png" alt="spanicon"/></span>
                                       </div>
                                       <div className="icon-content">
                                           <h4 className="mt-tilte m-b0">{this.props.language === 'EN'? 'Renting and placement of scaffolding, 20 years now!' : 'Μέγιστη ασφάλεια & άμεση ανταπόκριση!'}</h4>
                                       </div>
                                   </div>
                               </div>
                           </div>


                       </div>
                   </div>
               </div>

           </div>
      {/*section clients */}
      <div className="section-full p-t10 bg-white ">
         <div className="container">
           <div className="row">
                                             <div className="col-lg-12">
                                                <div className="mt-separator-outer separator-center">
                                                  <div className="mt-separator">
                                                    {this.props.language === 'EN'?  <h2 className="text-black text-uppercase sep-line-one ">
                                                      <span className="font-weight-300 text-primary">Our</span> Clients</h2>
                                                    :   <h2 className="text-black text-uppercase sep-line-one ">
                                                      Οι <span className="font-weight-300 text-primary">Πελατες</span> μας</h2> }

                                                  </div>
                                                </div>

                                               <div className="owl-carousel gallery-owl-nav" data-autoplay="true" data-responsive-lg="5" data-responsive-md="5" data-responsive-sm="3" data-responsive-xs="2" data-nav="false" data-dots="true" data-filters=".gallery-filters" data-margin="0" data-loop="true">



                                                       {this.props.data.clients.entities.filter((item)=> item.fieldImage).map((item,i)=> {
                                                         return (
                                                           <div className={`vertical-item item-gallery mr-2 content-absolute text-center ds `}>
                                                             <div className="containertv">
                                                               <div className="containerscreen">
                                                             <div className="item-media item-media-custom2 item-media-custom3">
                                                              <img style={{width:'354',height:'121'}}  src={item.fieldImage.imageFile.publicURL} alt={item.fieldImage.alt}/>

                                                             </div>
                                                             </div>
                                                              </div>
                                                           </div>
                                                         )
                                                       })}
















                                               </div>


                                             </div>
                                           </div>




         </div>
     </div>
      {/*section Footer */}

       <Footer social={this.props.social} logourl={this.props.footerlogo} language={this.props.language} menu={this.props.footermenu} info={this.props.data.contactInfo}/>

 </div>
 </div>
    )
  }
}
export default Mainpage
