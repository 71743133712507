import React from 'react'
import Header from '../../components/header'
import PageWrapper from '../../components/pagewrapper'
import Mainpage from '../../components/mainpage'
import { graphql } from "gatsby"
import {Helmet} from 'react-helmet'


class Index extends React.Component {
  render() {
    let menu = this.props.data.drupal.menuByName.links
    let langcontainer=[{id:'EL', path:'/el'}, {id:'EN', path:'/en'}]
    return (
      <PageWrapper>
        <Helmet>


          {this.props.data.drupal.meta.entityMetatags.map((item,i) => {
          if(item.key === 'title') {
          return (
          <title key={i}>{item.value}</title>
          )
          }
          else if(item.key === 'canonical') {
          return (
          <link key={i} rel="canonical" href={this.props.path} />
          )
          }

          else if(item.key === 'description') {
          return (
          <meta key={i} name="description" content={item.value}/>
          )
          }

          else {
          return null;
          }
          })}







        </Helmet>
        
          <Header 
            info={this.props.data.drupal.contactInfo} 
            logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail. imageFile.publicURL} 
            langcontainer={langcontainer} 
            language='EN' 
            path='/' 
            menu={menu}
          />
          
          <Mainpage  
            social={this.props.data.drupal.social} 
            footerlogo={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} 
            language='EN' 
            footermenu={menu}
            data={this.props.data.drupal}
          />

      </PageWrapper>

    )
  }
}

export default Index;

export const query = graphql`
  query {

    drupal {

      meta:nodeById(id: "277", language: EN) {

entityMetatags {
  value
  key
}
}



      footerLogo:nodeById(id: "25") {
       ... on DRUPAL_NodeLogo {

         fieldLogoimage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 publicURL
               }
             }
           }
         }
       }
     }
      headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 publicURL
               }
             }
           }
         }
   }
 }
      contactInfo: nodeById(id: "26", language: EN) {
     title
     ... on DRUPAL_NodeContactInfo {
       fieldAddress
       fieldEmail
       fieldPhone
     }
   }
   menuByName(name: "main", language: EN) {
     links {
       label
       url {
         path
       }
     }
   }

   social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
      entities {
        ... on DRUPAL_NodeSocialLinks {

          fieldIcon
          fieldLink
        }
      }
    }
    certs:nodeQuery(filter: {conditions: [{field: "type", value: "certifications"}]}, sort: {field: "title"},limit: 50) {
  entities(language: EN) {
    ... on DRUPAL_NodeCertifications {
        entityLabel
         fieldPdf {
           entity {
             url
           }
           pdfFile {
             publicURL
           }
         }
         fieldImage {
           alt
           entity {
             url
           }
           imageFile {
             publicURL
           }
         }
       }
  }
}
   services: nodeQuery(filter: {conditions: [{field: "type", value: "services"}]}) {
  entities(language: EN) {
    ... on DRUPAL_NodeServices {
      body {
        processed
      }
      title
      fieldServiceicon {
             entity {
               thumbnail {
                 url
                 alt
                 imageFile {
                   publicURL
                 }
               }
             }
           }
    }
  }
}

workservices:nodeQuery(filter: {conditions: [{field: "type", value: "workservices", language: EN}]}, limit: 50, sort: [{field: "field_simantiko"}]) {
 entities(language: EN) {
        ... on DRUPAL_NodeWorkservices {
          body {
            summary
          }
          entityLabel
          fieldWorkimage {
              entity {
                thumbnail {
                  url
                  alt
                  imageFile {
                    id
                  childImageSharp {
                    fixed(width: 350, height: 300, quality: 80) {
                      src
                    }
                    resize(width: 1200, height: 900, fit: COVER, quality: 80) {
                      src
                    }
                  }
                  publicURL
                }
                }
              }
            }
          fieldCategory {
            entity {
              name
            }
          }
        }
      }
}

about:nodeQuery(filter: {conditions: [{field: "type", value: "about"}]}) {
entities(language: EN) {
  ... on DRUPAL_NodeAbout {
    entityLabel
    body {
      processed
    }
    fieldImageForRegionCertifica {
             entity {
               thumbnail {
                 url
                 alt
                 imageFile {
                   childImageSharp {
                     resize(width: 900, height: 700) {
                       src
                     }
                   }
                 }
               }
             }
           }
           fieldAbouticon {
               entity {
                 thumbnail {
                   url
                   alt
                   imageFile {
                     childImageSharp {
                       resize(width: 900, height: 700) {
                         src
                       }
                     }
                   }
                 }
               }
             }
  }
}
}

clients:nodeQuery(filter: {conditions: [{field: "type", value: "clients"}]}, limit:50) {
 entities {
   ... on DRUPAL_NodeClients {
     fieldImage {
       alt
       entity {
         url
       }
       imageFile {
         publicURL
       }
     }
   }
 }
}

   slidequery: nodeQuery(filter: {conditions: [{field: "type", value: "slider"}]}) {
  entities(language: EN) {
    ... on DRUPAL_NodeSlider {
      body {
        processed
      }
      fieldSlideimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                childImageSharp {
                  resize(width: 1800, height: 800, fit: COVER, quality: 80) {
                    src
                  }
                }
              }
            }
          }
        }
      fieldButton {
            url {
              path
            }
            title
          }
    }
  }
}
 }
  }
`
